

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
to set the menu collapse trigger screen width 
@media (min-width: 600px){
.navbar-expand-md {
    flex-flow: row nowrap ;
    justify-content: flex-start;
}
}
@media (min-width: 600px){
  .navbar-expand-md .navbar-collapse{
    display: flex!important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  } */

.py-5{
  margin-bottom: 1.88rem;
}
.h2{
  font-family: Roboto Arial sans-serif;
}

.active-menu
{
  color:#233F87 !important;
  background-color: white;
  border-radius: 5px
}

.header-top a:hover{
  color:white
}

a:hover{
  text-decoration: none;
  
}
a div p:hover{
  text-decoration: none;
}

a .title:hover{
  text-decoration:none;
}


input[type='text']{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.btn-secondary {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #0c83e2;
  border-color: #0c7cd5;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 166, 245, 0.5);
  background-color: #2196f3;
  border-color:#2196f3;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #0c7cd5;
  border-color: #0b75c9;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 166, 245, 0.5);
}

.btn-outline-secondary {
  color: #2196f3;
  border-color: #2196f3;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.5);
  
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #2196f3;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.5);
}


.servererror{
  width: 100%;
  text-align: center;
  font-weight:500;
  font-size:3rem;
  color: #233F87;
}


.servererror h1{
  font-size: 10vw;
}

.servererror div{

  font-size: 1.8vw;
  color: rgb(39, 33, 36);
}

.navbar-expand-md.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.main-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
}

.btn-primary {
  color: #fff;
  background-color: #233F87;
  border-color: #233F87;   
  margin-right:10px
}

.btn-md.btn-primary{
  min-width:140px;
}
.p-12{
  padding: 10em 0 0;
}