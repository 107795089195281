@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap);
body {
  font-family: "Roboto", Arial, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #272124;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active-menu
{
  color:white !important;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {

  position: relative;
  width: 100%;
  padding-right: 10px; 
  padding-left: 10px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0; 
  margin-left: 0
}

.navbar-brand{

  padding-top: 0;
  padding-bottom: 0;
}


.height100vh{

  min-height:100vh;
}

.contact-email
{
  color:#233f87 !important;
}



/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
to set the menu collapse trigger screen width 
@media (min-width: 600px){
.navbar-expand-md {
    flex-flow: row nowrap ;
    justify-content: flex-start;
}
}
@media (min-width: 600px){
  .navbar-expand-md .navbar-collapse{
    display: flex!important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  } */

.py-5{
  margin-bottom: 1.88rem;
}
.h2{
  font-family: Roboto Arial sans-serif;
}

.active-menu
{
  color:#233F87 !important;
  background-color: white;
  border-radius: 5px
}

.header-top a:hover{
  color:white
}

a:hover{
  text-decoration: none;
  
}
a div p:hover{
  text-decoration: none;
}

a .title:hover{
  text-decoration:none;
}


input[type='text']{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.btn-secondary {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #0c83e2;
  border-color: #0c7cd5;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 166, 245, 0.5);
  background-color: #2196f3;
  border-color:#2196f3;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #0c7cd5;
  border-color: #0b75c9;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 166, 245, 0.5);
}

.btn-outline-secondary {
  color: #2196f3;
  border-color: #2196f3;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.5);
  
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #2196f3;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #2196f3;
  border-color: #2196f3;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.5);
}


.servererror{
  width: 100%;
  text-align: center;
  font-weight:500;
  font-size:3rem;
  color: #233F87;
}


.servererror h1{
  font-size: 10vw;
}

.servererror div{

  font-size: 1.8vw;
  color: rgb(39, 33, 36);
}

.navbar-expand-md.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.main-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
}

.btn-primary {
  color: #fff;
  background-color: #233F87;
  border-color: #233F87;   
  margin-right:10px
}

.btn-md.btn-primary{
  min-width:140px;
}
.p-12{
  padding: 10em 0 0;
}
